:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.swiper-contianer-multi {
  position: relative;
  --swiper-theme-color: $base-color-golden;
  --swiper-pagination-color: $base-color-golden;
  width: 100%;
}
.swiper-contianer-multi .swiper-slide {
  border: 1px solid #999999;
  border-radius: 8px;
}
.swiper-contianer-multi .swiper-slide .img-item {
  padding: 20px;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.swiper-contianer-multi .swiper-slide .img-item > img {
  width: 100%;
  display: block;
}
.swiper-contianer-multi .swiper-wrapper {
  padding-bottom: 20px;
}