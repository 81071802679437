:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.site-org .site-org-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
}
.site-org .site-org-wrapper .site-org-left {
  width: 40%;
  border-radius: 8px;
}
.site-org .site-org-wrapper .site-org-left img {
  width: 100%;
  border-radius: 8px;
}
.site-org .site-org-wrapper .site-org-right {
  padding-left: 20px;
  flex: 1;
}
.site-org .site-org-wrapper .site-org-right .title {
  font-size: 1.42rem;
  font-weight: 600;
  margin-bottom: 10px;
}
@media (max-width: 500px) {
  .site-org .site-org-wrapper {
    flex-direction: column;
  }
  .site-org .site-org-wrapper .site-org-left {
    width: 100%;
  }
  .site-org .site-org-wrapper .site-org-right {
    padding-left: 0px;
  }
  .site-org .site-org-wrapper .site-org-right .title {
    margin-top: 10px;
  }
}