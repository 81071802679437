:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.image-list-contianer .image-list-wrapper {
  margin-top: 20px;
  border-radius: 8px;
  padding: 0;
}
.image-list-contianer .image-list-wrapper .image-list-item {
  border: 1px solid #c78a29;
  padding: 10px;
  border-radius: 5px;
  background: #fff;
  height: 12rem;
  overflow: hidden;
}
.image-list-contianer .image-list-wrapper .image-list-item > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}