:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.site-dialog .site-notice-dialog-box {
  width: 90%;
  padding: 0px;
  border: 0px solid #ebeef5;
  max-width: 800px;
  border-radius: 4px;
  font-size: 18px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  overflow: auto;
  backface-visibility: hidden;
  position: relative;
}
.site-dialog .site-notice-dialog-box .site-notice-content {
  padding: 0px;
  color: #606266;
  font-size: 14px;
  overflow: auto;
  font-size: 0;
  background: none;
  width: 100%;
  margin: 0 auto;
}
.site-dialog .site-notice-dialog-box .site-notice-content img {
  border-radius: 4px;
  display: block;
  width: 100%;
}