:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.flower-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flower-title .title {
  margin: 0px 20px;
}
.flower-title .flower img {
  width: 14px;
}