:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.site-circle-menu {
  margin: 0 auto;
  width: 100%;
  height: calc(21.4861235452vw - 50px);
}
.site-circle-menu .pos-pc {
  max-width: 1117px;
  width: 80%;
  height: 21.4861235452vw;
  position: relative;
  margin: 0 auto;
  top: -50px;
}
.site-circle-menu .pos-pc .pos {
  position: absolute;
}
.site-circle-menu .pos-pc .pos .c1,
.site-circle-menu .pos-pc .pos .c2 {
  background: #ffffff;
  border-radius: 100%;
  border: 2px solid #c78a29;
  padding: 16px;
}
.site-circle-menu .pos-pc .pos img {
  width: 100%;
  object-fit: contain;
}
.site-circle-menu .pos-pc .pos.pos1 {
  width: 16.15%;
  max-width: 180px;
  top: 0px;
  left: 0px;
  z-index: 50;
}
.site-circle-menu .pos-pc .pos.pos2 {
  width: 19.193%;
  max-width: 214px;
  top: -18%;
  left: 12%;
  z-index: 51;
  cursor: pointer;
}
.site-circle-menu .pos-pc .pos.pos3 {
  width: 10.135%;
  max-width: 113px;
  top: 4%;
  left: 27%;
  z-index: 49;
}
.site-circle-menu .pos-pc .pos.pos4 {
  width: 19.193%;
  max-width: 214px;
  top: 8%;
  left: 33%;
  z-index: 51;
  cursor: pointer;
}
.site-circle-menu .pos-pc .pos.pos5 {
  width: 19.193%;
  max-width: 214px;
  top: 11%;
  left: 45%;
  z-index: 49;
}
.site-circle-menu .pos-pc .pos.pos6 {
  width: 19.193%;
  max-width: 214px;
  top: -6%;
  left: 58%;
  z-index: 51;
  cursor: pointer;
}
.site-circle-menu .pos-pc .pos.pos7 {
  width: 10.135%;
  max-width: 113px;
  top: 4%;
  left: 76%;
  z-index: 49;
}
.site-circle-menu .pos-pc .pos.pos8 {
  width: 19.193%;
  max-width: 214px;
  top: 4%;
  left: 82.5%;
  z-index: 49;
  cursor: pointer;
}
.site-circle-menu .pos-mobile {
  display: none;
  padding: 20px;
}
.site-circle-menu .pos-mobile .pos-mob-list {
  display: flex;
  justify-content: space-between;
}
.site-circle-menu .pos-mobile .pos-mob-list .pos-item {
  width: 23%;
}
.site-circle-menu .pos-mobile .pos-mob-list .pos-item img {
  width: 100%;
  object-fit: contain;
}
.site-circle-menu .btn {
  width: 146px;
  height: 146px;
  background: #ffffff;
  border-radius: 100%;
  border: 2px solid #c78a29;
  color: #c78a29;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.site-circle-menu .btn img {
  width: 41px;
  height: 42px;
}
.site-circle-menu .btn .txt {
  margin-top: 5px;
}
.site-circle-menu .btn-reg {
  border: 0px solid #c78a29;
  background: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/icon-edit-red.png") no-repeat;
  background-size: 100% 100%;
  color: #fff;
}

@media (min-width: 1117px) {
  .site-circle-menu {
    height: 200px !important;
  }
  .site-circle-menu .pos-pc {
    height: 280px !important;
  }
}
@media (max-width: 900px) {
  .site-circle-menu {
    height: auto !important;
  }
  .site-circle-menu .pos-pc {
    display: none;
  }
  .site-circle-menu .pos-mobile {
    display: block;
  }
}
@media (min-width: 901px) {
  .site-circle-menu .pos-pc {
    display: block;
  }
  .site-circle-menu .pos-mobile {
    display: none;
  }
}