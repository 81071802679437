:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.site-dialog {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  text-align: center;
  z-index: 2015;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.site-dialog .site-dialog-close {
  color: #fff;
  font-size: 22px;
  margin-bottom: 20px;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
}
.site-dialog .site-dialog-box {
  width: 80%;
  max-height: 80%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  font-size: 18px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  overflow: hidden;
  backface-visibility: hidden;
  padding: 20px;
  position: relative;
}
.site-dialog .site-dialog-box .site-dialog-header {
  position: relative;
  padding: 0px 15px 15px 15px;
  text-align: center;
  border-bottom: 1px solid #c78a29;
  font-weight: 600;
}
.site-dialog .site-dialog-box .site-dialog-content {
  padding: 15px;
  color: #606266;
  font-size: 14px;
  overflow: auto;
  height: calc(100% - 20px);
}