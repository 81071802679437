@charset "UTF-8";
:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.swiper-contianer {
  position: relative;
  --swiper-theme-color: $base-color-golden;
  --swiper-pagination-color: $base-color-golden; /* 两种都可以 */
  width: 100%;
}
.swiper-contianer .swiper-slide > img {
  width: 100%;
}

.swiper-pagination-bullet-active {
  background: rgb(199, 138, 41);
}

.swiper-container-horizontal > .bullet-bottom.swiper-pagination-bullets {
  bottom: -20px;
}