:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.swiper-contianer-multi-home {
  position: relative;
  --swiper-theme-color: $base-color-golden;
  --swiper-pagination-color: $base-color-golden;
  width: 100%;
}
.swiper-contianer-multi-home .swiper-slide .img-item {
  margin: 0 auto;
}
.swiper-contianer-multi-home .swiper-slide .img-item > img {
  width: 100%;
  object-fit: contain;
  display: block;
}
.swiper-contianer-multi-home .swiper-wrapper {
  align-items: flex-end;
}