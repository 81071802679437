:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.agenda-list-container .agenda-list-wrapper .agenda-item {
  margin-top: 20px;
}
.agenda-list-container .agenda-list-wrapper .agenda-item .date {
  background-color: #fff;
  height: 40px;
  font-size: 20px;
  color: #000;
  line-height: 40px;
  text-align: left;
  padding: 0px 15px;
}
.agenda-list-container .agenda-list-wrapper .agenda-item .date .txt {
  margin-left: 10px;
}
.agenda-list-container .agenda-list-wrapper .agenda-item .date .subtxt {
  font-size: 14px;
}
.agenda-list-container .agenda-list-wrapper .agenda-item .list {
  padding: 0;
  margin: 0;
}
.agenda-list-container .agenda-list-wrapper .agenda-item .list .item {
  color: #000;
  display: flex;
  margin-top: 10px;
  align-items: baseline;
}
.agenda-list-container .agenda-list-wrapper .agenda-item .list .item .datetime {
  width: 110px;
  display: flex;
  align-items: center;
}
.agenda-list-container .agenda-list-wrapper .agenda-item .list .item .datetime .dian {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border: 1px solid #c41230;
  margin-right: 5px;
  background: #c41230;
}
.agenda-list-container .agenda-list-wrapper .agenda-item .list .item .subitem {
  flex: 1;
}
.agenda-list-container .agenda-list-wrapper .agenda-item .list .item .subitem .txt {
  font-size: 14px;
  background: rgba(255, 255, 255, 0.25);
  padding: 15px;
  width: 100%;
  text-align: left;
  padding: 15px;
  margin-top: 10px;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.agenda-list-container .agenda-list-wrapper .agenda-item .list .item .subitem .address {
  padding: 5px 0px;
  margin-top: 5px;
  font-weight: bold;
  font-style: italic;
  background: rgba(255, 255, 255, 0.25);
  color: #666;
  font-size: 12px;
}
.agenda-list-container .agenda-list-wrapper .agenda-item .list .item .subitem .address .head {
  margin-right: 3px;
}