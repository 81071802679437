:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.site-circle {
  margin: 0 auto;
}
.site-circle .item {
  background: #ffffff;
  border-radius: 100%;
  border: 2px solid #c78a29;
  padding: 16px;
}
.site-circle .item.first {
  width: 48px;
  height: 48px;
}
.site-circle .btn {
  width: 146px;
  height: 146px;
  background: #ffffff;
  border-radius: 100%;
  border: 2px solid #c78a29;
  color: #c78a29;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.site-circle .btn img {
  width: 41px;
  height: 42px;
}
.site-circle .btn .txt {
  margin-top: 5px;
}
.site-circle .btn-reg {
  border: 0px solid #c78a29;
  background: url("https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/icon-edit-red.png") no-repeat;
  background-size: 100% 100%;
  color: #fff;
}