:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.item-tip {
  text-align: center;
}
.item-tip .title {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #CA3935;
}
.item-tip > img {
  width: 100%;
}