:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.newmore {
  border-radius: 6px;
  border: 1px solid #999999;
  text-align: center;
  color: #999999;
  padding: 5px 15px;
  cursor: pointer;
}

.tip-x {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tip-x .left {
  display: flex;
  align-items: center;
}
.tip-x .title {
  font-size: 2.5rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgb(199, 138, 41);
  margin-left: 10px;
}
.tip-x .subtitle,
.tip-x .date {
  font-size: 1.07rem;
  margin-left: 20px;
}
.tip-x .title-white {
  color: #fff !important;
}
.tip-x .image {
  width: 2.64rem;
}