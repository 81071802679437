:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.langclass .el-dropdown-menu__item:focus,
.langclass .el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #fff;
  color: #CA3935 !important;
}

.el-menu {
  white-space: nowrap;
}

.el-menu--horizontal > .el-menu-item {
  display: inline !important;
}

.el-menu--horizontal > .el-submenu {
  display: inline !important;
}

.mobile-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 2000;
}
.mobile-menu .menu-layer {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.7s ease;
  transform: translateX(-100%);
}
.mobile-menu .menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  transform: translateX(-100%);
  background: #efeade;
  background-image: url(https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/mainbg.png) !important;
}
.mobile-menu .menu-box .el-menu {
  background: #efeade;
  background-image: url(https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/mainbg.png) !important;
}
.mobile-menu .menu-box .el-submenu__title:hover {
  color: #CA3935 !important;
  background: none !important;
}
.mobile-menu .menu-box .el-menu-item:focus,
.mobile-menu .menu-box .el-menu-item:hover {
  color: #CA3935 !important;
  background: none !important;
}
.mobile-menu .menu-box .menu-close {
  height: 50px;
  position: relative;
}
.mobile-menu .menu-box .menu-close .close {
  text-align: right;
  font-size: 24px;
  width: 24px;
  color: #000;
  cursor: pointer;
  transition: all 0.9s ease;
  position: absolute;
  top: 10px;
  right: 10px;
}
.mobile-menu .menu-box .menu-close .close:hover {
  transform: rotate(360deg);
}
.mobile-menu .menu-box .menu-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
.mobile-menu .menu-box .menu-list .menu-item {
  position: relative;
  display: block;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .menu-box .menu-list .menu-item a {
  color: #fff;
  display: block;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 500;
}
.mobile-menu .menu-box .content {
  padding: 10px 25px;
}
.mobile-menu .menu-box .content .contact-us {
  color: #fff;
}
.mobile-menu .menu-box .content .contact-us .txt {
  font-size: 14px;
  margin-bottom: 10px;
}

.mobile-menu-header .mobile-menu {
  opacity: 1;
  visibility: visible;
}
.mobile-menu-header .mobile-menu .menu-layer {
  transform: translateX(0%);
}
.mobile-menu-header .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
  transform: translateX(0%);
}

.site-header {
  position: relative;
  text-align: left;
  padding: 10px;
  background-image: url(https://iscfiles.oss-accelerate.aliyuncs.com/tmp/WCEC/2023/web/mainbg.png) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  z-index: 100;
  width: 100%;
}
.site-header .header-menu {
  margin-left: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.site-header .header-menu .mobile-menu-icon {
  font-size: 40px;
  display: none;
  margin-left: 10px;
}
.site-header .header-menu .header-logo {
  display: flex;
}
.site-header .header-menu .menu-list {
  padding: 0;
  margin: 0;
}
.site-header .header-menu .menu-list .el-menu-item {
  height: 30px !important;
  line-height: 30px !important;
}
.site-header .header-menu .menu-list .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 30px !important;
  line-height: 30px !important;
}
.site-header .header-menu .menu-list .menu-item:first-child {
  margin-left: 5px;
}
.site-header .header-menu .menu-list .menu-item a {
  font-weight: 500;
  letter-spacing: 1px;
}
.site-header .header-menu .menu-list .menu-item:hover a {
  color: #ca3935;
  font-weight: 600;
}
.site-header .header-menu .menu-list .menu-item.active {
  background: #ca3935;
  border-radius: 0px 14px 0px 14px;
}
.site-header .header-menu .menu-list .menu-item.active a {
  color: #fff;
}
.site-header .header-menu .header-btn-tool {
  padding: 5px 10px;
  width: 101px;
  flex-shrink: 0;
}
.site-header .btn-reg-wcec {
  background: #ca3935;
  border-radius: 0px 14px 0px 14px;
  padding: 5px 10px;
  color: #fff;
}
.site-header .btn-reg,
.site-header .btn-tool-reg {
  cursor: pointer;
  color: #fff;
  padding: 8px 10px;
  background: linear-gradient(90deg, #ca3935 0%, #bb08ab 100%);
  border-radius: 4px;
  border: 1px solid #bb08ab;
  display: block;
  text-align: center;
}
.site-header .btn-reg {
  display: inline-block;
}
.site-header .el-menu-item,
.site-header .el-submenu__title {
  padding: 0px 10px;
}
.site-header .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
  margin-left: 0px;
  margin-top: 0px;
}