:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.hidden {
  display: none;
}

.list-wrapper .list-more {
  padding: 20px 5px;
}
.list-wrapper .list .list-item-wrapper {
  border-radius: 8px;
  padding: 0;
  margin-top: 20px;
}
.list-wrapper .list .list-item-wrapper .list-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #c78a29;
}
.list-wrapper .list .list-item-wrapper .list-item .list-media {
  width: 100%;
}
.list-wrapper .list .list-item-wrapper .list-item .list-media img {
  object-fit: cover;
  width: 100%;
  height: 14rem;
  border-radius: 8px 8px 0 0;
  display: block;
}
.list-wrapper .list .list-item-wrapper .list-item .list-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.42rem;
}
.list-wrapper .list .list-item-wrapper .list-item .list-content .list-right-top {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
}
.list-wrapper .list .list-item-wrapper .list-item .list-content .list-right-top .list-title {
  padding: 0;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 16px;
  height: 42px;
}
.list-wrapper .list .list-item-wrapper .list-item .list-content .list-right-top .list-text {
  font-size: 14px;
  height: 36px;
}