:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.seamless-warp2 {
  overflow: hidden;
  height: 50px;
  line-height: 50px;
  width: 100%;
  margin: 0 auto;
}
.seamless-warp2 .item {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0;
  color: #fff;
  margin-right: 20px;
  white-space: nowrap;
  font-size: 18px;
  color: #171b3d;
  line-height: 50px;
}
.seamless-warp2 .item.zh {
  font-family: KaiTi;
  font-size: 20px;
  font-weight: 600;
}
.seamless-warp2 ul.item {
  margin: 0 autuo;
  list-style: none;
  padding: 0 !important;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.seamless-warp2 ul.item li {
  height: 50px;
  line-height: 50px;
  padding: 0;
  color: #fff;
  margin-right: 10px;
  white-space: nowrap;
  font-size: 18px;
  color: #ffffff;
  line-height: 50px;
}

.slogan {
  width: 100%;
  height: 50px;
  padding: 0px 20px;
  position: relative;
  z-index: 10;
  background: linear-gradient(90deg, #f5e693 0%, #cc9936 29%, #cc9935 64%, #f2e28d 69%, #cc9935 77%, #c99e3b 100%);
}