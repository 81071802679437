:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.site-anchor [class*=floor] {
  height: 780px;
  padding: 20px;
}
.site-anchor [class*=floor]:nth-child(odd) {
  background: #b5ff8a;
}
.site-anchor [class*=floor]:nth-child(even) {
  background: #6db9ff;
}